@use "sass:color";@use 'alva' as *;.ComponentTable {
  display: flex;
  flex-flow: column;

  .export-button {
    align-self: flex-end;
    margin-bottom: 3px;
  }

  .rt-resizable-header-content {
    text-align: left;
  }

  .ReactTable .rt-tr.-even {
    background-color: initial;
  }

  .no-data-component p {
    margin: 10px;
  }

  .help-icon {
    -webkit-text-stroke: 1px white;
    font-size: 1.3rem;
  }
}

.component-list-loader {
  margin: 1rem;
}

@use "sass:color";@use 'alva' as *;.AvailabilityWidget {
  .help-icon {
    margin-left: 10px;
  }

  .MeterTable,
  .NvpTable,
  .GeneratorDataTable {
    margin-bottom: 20px;

    .MeterCellContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 1em;

      .MeterName {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .icon-help-text-tooltip {
      white-space: initial;
    }
  }
}

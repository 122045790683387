@use "sass:color";@use 'alva' as *;.ParkabrechnungContractModalUI {
  .ParkabrechnungContractModal-validity {
    display: flex;
    flex-direction: column;
  }

  .ParkabrechnungContractModal-label {
    margin-top: 24px;
    margin-bottom: 10px;
  }

  .TabBar {
    margin-top: 40px;
  }
}

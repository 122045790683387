@use "sass:color";@use 'alva' as *;.archived-invoices-container {
  margin-top: 50px;
}

.reversed-invoice-component-container {
  padding-top: 20px;
  padding-bottom: 20px;
}

.reversed-invoice-cell-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 20px;
  margin-left: 10px;
  align-items: center;
}

.reversed-invoice-status-and-mail-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 300px;
}

.no-reversed-available {
  margin-left: 20px;
}

.reversal-invoice-icon {
  width: 20px;
  margin-right: 10px;
}

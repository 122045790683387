@use "sass:color";@use 'alva' as *;@use "../RetryModal.scss" as *;

.meter-dropdown {
  min-width: 300px;
  margin-right: 5px;

  .FormField {
    margin: 0;
  }

  &.no-meter {
    border: 1px solid $danger-color;
    border-radius: 5px;

    div {
      border: none;
    }
  }
}

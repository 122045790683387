@use "sass:color";@use 'alva' as *;.ProjectManager {
  input.checkbox {
    transform: scale(1.5);
    margin: -5px;
  }

  .custom-checkbox {
    padding-left: 0;
    cursor: pointer;
  }

  .custom-checkbox label {
    cursor: pointer;
  }

  .controls {
    margin-bottom: 30px;
  }

  .controls button {
    margin-right: 3px;
    width: 100px;
  }
}

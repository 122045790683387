@use "sass:color";@use 'alva' as *;.ImportLogsTable {
  .StatusCell {
    &.success {
      color: $success-color;
    }

    &.error {
      color: $danger-color;
    }
  }

  .MessageCell {
    display: flex;
    width: 100%;

    .message-icon {
      margin-right: 10px;
    }

    .message-box {
      flex-grow: 1;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

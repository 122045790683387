@use "sass:color";@use 'alva' as *;.configuration-assignment-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.missing-data-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.missing-data-button {
  margin-top: 3px;
}

@use "sass:color";@use 'alva' as *;.ThreeWayFilter {
  .dropdown-item.special-option {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .dropdown-item .btn-group {
    width: unset !important;
    height: unset !important;
    margin-right: 10px;
  }

  .dropdown-menu {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;

    .scrollable-menu-items {
      max-height: 350px;
      padding: 0.5rem 0;
      overflow: auto;

      .dropdown-item {
        &:active {
          background-color: unset;
        }

        .StatusSymbol {
          margin-bottom: -3px;
        }
      }
    }
  }
}

@use "sass:color";@use 'alva' as *;.variant-selection {
  margin-bottom: 1rem;
  float: right;
}

.popover {
  max-width: 450px;
  .popover-inner {
    min-width: 300px;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

$shadow-hover-shadow:
  1px 0 0 #dadce0,
  -1px 0 0 #dadce0,
  0 1px 2px 0 rgba(88, 103, 221, 0.3),
  0 1px 3px 1px rgba(88, 103, 221, 0.3);

@mixin shadow-hover {
  &:hover {
    -webkit-box-shadow: $shadow-hover-shadow;
    -moz-box-shadow: $shadow-hover-shadow;
    box-shadow: $shadow-hover-shadow;
    z-index: 1;
  }
}

.button-spacing {
  margin-right: 3px;
}

/**
  * this rule is included in the newer reactstrap styles but not in the one we are using
  * it is needed to make the disabled buttons look disabled
  */
.btn.disabled,
.btn:disabled {
  pointer-events: none;
}

.loader {
  display: flex;
  justify-content: center;
}

@use "sass:color";@use 'alva' as *;.BatchImportErrorDisplay {
  .error-display-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    font-size: 15px;
    margin-bottom: 5px;
  }
}

@use "sass:color";@use 'alva' as *;.SiteSetupAssistant {
  display: flex;
  flex-direction: column;

  .site-setup-assistant-portlet-body {
    flex-grow: 1;

    .site-setup-form {
      padding: 0 5%;
    }
  }
}

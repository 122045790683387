@use "sass:color";@use 'alva' as *;.name-cell {
  color: #5867dd;
}

.name-cell.disallow-modal:hover {
  cursor: initial;
  text-decoration: none;
}

.name-cell:hover {
  cursor: pointer;
  text-decoration: underline;
}

@use "sass:color";@use 'alva' as *;.DeleteConfirmationModal {
  .icon {
    font-size: 25px;
    margin-right: 8px;
  }

  .error-icon {
    filter: saturate(200%);
  }
}

/**
 * @license
 *
 * Font Family: General Sans
 * Designed by: Frode Helland
 * URL: https://www.fontshare.com/fonts/general-sans
 * Â© 2024 Indian Type Foundry
 *
 * Font Styles:
 * General Sans Variable(Variable font)
 * General Sans Variable Italic(Variable font)
 * General Sans Extralight
 * General Sans Extralight Italic
 * General Sans Light
 * General Sans Light Italic
 * General Sans Regular
 * General Sans Italic
 * General Sans Medium
 * General Sans Medium Italic
 * General Sans Semibold
 * General Sans Semibold Italic
 * General Sans Bold
 * General Sans Bold Italic
 *
*/

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 700.0;
*
* available axes:

* 'wght' (range from 200.0 to 700.0)

*/

@use "global-fonts" as *;

@font-face {
  font-family: "GeneralSans-Variable";
  src:
    url("./fonts/alva/GeneralSans-Variable.woff2") format("woff2"),
    url("./fonts/alva/GeneralSans-Variable.woff") format("woff"),
    url("./fonts/alva/GeneralSans-Variable.ttf") format("truetype");
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 700.0;
*
* available axes:

* 'wght' (range from 200.0 to 700.0)

*/

@font-face {
  font-family: "GeneralSans-VariableItalic";
  src:
    url("./fonts/alva/GeneralSans-VariableItalic.woff2") format("woff2"),
    url("./fonts/alva/GeneralSans-VariableItalic.woff") format("woff"),
    url("./fonts/alva/GeneralSans-VariableItalic.ttf") format("truetype");
  font-weight: 200 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "GeneralSans-Extralight";
  src:
    url("./fonts/alva/GeneralSans-Extralight.woff2") format("woff2"),
    url("./fonts/alva/GeneralSans-Extralight.woff") format("woff"),
    url("./fonts/alva/GeneralSans-Extralight.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "GeneralSans-ExtralightItalic";
  src:
    url("./fonts/alva/GeneralSans-ExtralightItalic.woff2") format("woff2"),
    url("./fonts/alva/GeneralSans-ExtralightItalic.woff") format("woff"),
    url("./fonts/alva/GeneralSans-ExtralightItalic.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "GeneralSans-Light";
  src:
    url("./fonts/alva/GeneralSans-Light.woff2") format("woff2"),
    url("./fonts/alva/GeneralSans-Light.woff") format("woff"),
    url("./fonts/alva/GeneralSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "GeneralSans-LightItalic";
  src:
    url("./fonts/alva/GeneralSans-LightItalic.woff2") format("woff2"),
    url("./fonts/alva/GeneralSans-LightItalic.woff") format("woff"),
    url("./fonts/alva/GeneralSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "GeneralSans-Regular";
  src:
    url("./fonts/alva/GeneralSans-Regular.woff2") format("woff2"),
    url("./fonts/alva/GeneralSans-Regular.woff") format("woff"),
    url("./fonts/alva/GeneralSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "GeneralSans-Italic";
  src:
    url("./fonts/alva/GeneralSans-Italic.woff2") format("woff2"),
    url("./fonts/alva/GeneralSans-Italic.woff") format("woff"),
    url("./fonts/alva/GeneralSans-Italic.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "GeneralSans-Medium";
  src:
    url("./fonts/alva/GeneralSans-Medium.woff2") format("woff2"),
    url("./fonts/alva/GeneralSans-Medium.woff") format("woff"),
    url("./fonts/alva/GeneralSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "GeneralSans-MediumItalic";
  src:
    url("./fonts/alva/GeneralSans-MediumItalic.woff2") format("woff2"),
    url("./fonts/alva/GeneralSans-MediumItalic.woff") format("woff"),
    url("./fonts/alva/GeneralSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "GeneralSans-Semibold";
  src:
    url("./fonts/alva/GeneralSans-Semibold.woff2") format("woff2"),
    url("./fonts/alva/GeneralSans-Semibold.woff") format("woff"),
    url("./fonts/alva/GeneralSans-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "GeneralSans-SemiboldItalic";
  src:
    url("./fonts/alva/GeneralSans-SemiboldItalic.woff2") format("woff2"),
    url("./fonts/alva/GeneralSans-SemiboldItalic.woff") format("woff"),
    url("./fonts/alva/GeneralSans-SemiboldItalic.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "GeneralSans-Bold";
  src:
    url("./fonts/alva/GeneralSans-Bold.woff2") format("woff2"),
    url("./fonts/alva/GeneralSans-Bold.woff") format("woff"),
    url("./fonts/alva/GeneralSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "GeneralSans-BoldItalic";
  src:
    url("./fonts/alva/GeneralSans-BoldItalic.woff2") format("woff2"),
    url("./fonts/alva/GeneralSans-BoldItalic.woff") format("woff"),
    url("./fonts/alva/GeneralSans-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

@use "sass:color";@use 'alva' as *;.EdaTable {
  display: flex;
  max-height: calc(100vh - 295px);
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);

  .ReactTable {
    overflow: auto;
    flex-grow: 1;
    border: 0;

    .rt-table {
      .rt-th {
        overflow: visible;

        .btn-group {
          width: 100%;
          height: 28px;

          .dropdown-toggle {
            width: 100%;
            padding: 0.35rem 16px 0.35rem 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .filter-dropdown-text {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }

      .rt-tr {
        overflow: hidden;
      }

      .rt-td {
        white-space: break-spaces;
        overflow-wrap: break-word;

        &.clickable-cell {
          cursor: pointer;
        }
      }
    }
  }

  &.showing-alert {
    max-height: calc(100vh - 345px);
  }

  .tag {
    display: inline-block;
    margin: 0.125em 0.5em 0.125em 0;
    padding: 0.125rem 0.375rem;
    font-size: 0.85em;
    font-weight: bold;
    border-radius: 2px;
    white-space: nowrap;
  }

  .no-data-component {
    text-align: center;
    margin: 20px;
  }
}

.tableSubTitle {
  font-weight: 800;
}

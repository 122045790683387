@use "sass:color";@use 'alva' as *;.ToggleSwitch {
  .m-switch label {
    display: flex;
    align-items: center;
  }

  .m-switch.m-switch--sm input:empty ~ span {
    margin-right: 10px;
  }
}

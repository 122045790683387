@use "sass:color";@use 'alva' as *;.DateRangeInput {
  margin-bottom: 10px;

  .grouped-fields {
    display: flex;
    align-items: center;
    gap: 10px;
    width: fit-content;
  }

  .date-range-start,
  .date-range-end {
    margin-bottom: 0;

    input {
      width: 100px;
    }
  }

  &.is-invalid {
    .date-range-start input,
    .date-range-end input {
      border-color: $danger-color;
    }
  }
}

@use "sass:color";@use 'alva' as *;.GeneratorWizard {
  .delivery-contraints-step {
    display: flex;
    flex-flow: column;
    align-items: stretch;

    .continue-button {
      margin-top: 20px;
      align-self: center;
    }
  }
}

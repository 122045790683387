@use "sass:color";@use 'alva' as *;.StructureViewFlowDiagram {
  position: relative;
  flex-grow: 1;
  &.connecting-nodes {
    border: 1px solid red;
  }

  &.edge-to-save {
    border: 1px solid green;
  }
  .react-flow__attribution {
    display: none;
  }
  .react-flow__pane {
    cursor: default;
    path.react-flow__edge-path:hover {
      stroke-width: 4 !important;
    }
    .react-flow__node {
      cursor: default;
    }
  }
}

@use "sass:color";@use 'alva' as *;img#logo {
  width: 100%;
}

#m_login .wide-panel {
  width: 50vw;
}

.default-logo {
  background-attachment: initial;
  background-image: url("../../../default/assets/img/login-background.jpg");
}

.smarendo-logo {
  background-attachment: initial;
  background-position: center;
  background-image: url("../../../roedl/assets/img/login-background.png");
}

.logo-text {
  & > div {
    margin: 10px 0px;

    img {
      max-width: 200px;
    }
  }
}

@use "sass:color";@use 'alva' as *;.Paragraph6CreditsPage {
  .credits-controls {
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;
  }

  .credits-buttons {
    display: flex;
    gap: 1rem;
    align-self: flex-end;
    margin-bottom: 1.5rem;
  }
}

@use "sass:color";@use 'alva' as *;.MeteringArrows {
  height: 100%;
  pointer-events: none;
  z-index: 1;
  .metering-arrow-container {
    position: absolute;
    display: flex;
  }
  .metering-arrows-flow-arrow-icon {
    width: 53px !important;

    &.green {
      filter: invert(51%) sepia(63%) saturate(4320%) hue-rotate(89deg)
        brightness(109%) contrast(110%);
    }
    &.blue {
      filter: invert(14%) sepia(93%) saturate(3224%) hue-rotate(234deg)
        brightness(95%) contrast(99%);
    }
    &.down {
      transform-origin: top;
      rotate: 90deg;
    }
    &.double-arrow {
      &.blue {
        &.down {
          position: relative;
          left: -145px;
        }
        &.right {
          position: relative;
          top: 20px;
          right: 78px;
        }
      }
      &.green {
        &.up {
          position: relative;
          right: 130px;
        }
        &.left {
          position: relative;
          right: 130px;
          top: 2px;
        }
      }
    }
    &.left {
      rotate: 180deg;
    }
    &.up {
      transform-origin: top;
      rotate: 270deg;
    }
  }
}

.metering-arrows-flow-arrow-label {
  font-weight: 700;
  position: relative;
  width: 40px;
  &.blue.double.top {
    left: 60px;
    top: 20px;
  }
  &.green.double.top {
    left: 20px;
    top: 2px;
  }
  &.blue.double.side {
    left: -85px;
    top: -10px;
  }
  &.green.double.side {
    left: 15px;
    top: -5px;
  }
  &.blue.side {
    left: 65px;
    top: -12px;
  }
  &.green.side {
    left: 48px;
    top: -7px;
  }
  &.blue.top {
    top: -18px;
    left: 50px;
  }
  &.green.top {
    top: -18px;
    left: 60px;
  }
}

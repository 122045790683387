@use "sass:color";@use 'alva' as *;.StartPage {
  .heading-row {
    margin-bottom: 2rem;
    margin-left: 0px;
    margin-right: 0px;
    align-items: center;
    justify-content: space-between;

    h3 {
      margin: 0px;
    }

    .react-select {
      width: 250px;
    }
  }
}

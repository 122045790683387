@use "sass:color";@use 'alva' as *;.MantinePagination {
  display: flex;
  align-items: center;
  gap: 20px;

  .mantine-Select-root {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .mantine-Select-label {
    margin: 0;
  }
}

@use "sass:color";@use 'alva' as *;.SwissArmyTable {
  .controls {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;

    button:not(.SearchBarWrapper > button) {
      margin-left: 3px;
    }

    .SearchBarWrapper {
      flex-grow: 1;
      margin-right: 50px;

      .search-bar-input {
        width: 100%;
        max-width: 470px;

        .search-bar-input-field {
          width: unset;
        }
      }
    }
  }
  .no-data-component {
    text-align: center;
    margin: 10px;

    p {
      margin: 0;
    }
  }
}

.icons-cell {
  .icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
  }
}

.icon-add-tertiary-button {
  border: 1px solid $brand-color !important;
  color: $brand-color !important;

  i {
    color: $brand-color !important;
  }
}

.icon-delete-button {
  border: none !important;
}

.icon-delete-button.active {
  background-color: white !important;
  color: $danger-color !important;

  i {
    color: $danger-color !important;
  }
}

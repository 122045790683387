@use "sass:color";@use 'alva' as *;.FictionalEnergyValuesTable {
  $input-height: 33.5px;

  // need this to override page-level definition that shouldn't apply here
  &.SwissArmyTable .controls {
    flex-direction: row;
  }

  // need this to hide "created" col that is necessary for React Table v6 to apply sorting of that column,
  // but we don't want to actually show this column in the FE
  .rt-th:nth-child(2),
  .rt-td:nth-child(2) {
    display: none;
  }

  .HeaderWithCustomSorting .header-wrapper {
    justify-content: flex-start;
  }

  .rt-td {
    display: flex;
    align-items: center;
    height: 49px;
  }

  .Dropdown {
    width: 100%;
    margin: 0;

    .react-select__control {
      min-height: $input-height;
      height: $input-height;
    }
  }

  .dropdown-input-wrapper {
    flex-grow: 1;
    flex-wrap: unset;
  }

  .InputWrapper:not(.dropdown-input-wrapper) {
    width: 100%;

    input {
      height: $input-height;
    }
  }

  .DateInput2 {
    width: 100%;
  }
}

@use "sass:color";@use 'alva' as *;.AdminDashboardHeader {
  position: absolute;
  width: calc(100vw - 150px);
  top: 22px;
  left: 150px;
  height: 47px;
  padding: 8px;
  background-color: #ffffff;
}

.AdminDashboardContent {
  padding-top: 100px;
  padding-left: 150px;
}

@media screen and (max-width: 993px) {
  .AdminDashboardHeader {
    width: 100%;
    left: 0;
  }

  .AdminDashboardContent {
    padding-left: unset;
  }
}

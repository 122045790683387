@use "sass:color";@use 'alva' as *;.AssessmentPage {
  .page-header-row {
    // screen width minus left menu + some other layout bits
    width: calc(100vw - 182px);
  }

  // when m-aside-left is display: none (the left menu bar)
  @media (max-width: 992px) {
    .page-header-row {
      width: 100vw;
    }
  }
}

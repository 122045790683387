@use "sass:color";@use 'alva' as *;.CreateEdaVersionForm {
  display: flex;
  flex-direction: column;

  .section-label {
    margin-bottom: 0;
    margin-top: 1rem;
  }

  .referenzen-container {
    margin-left: 1rem;
    margin-bottom: 1rem;
  }

  .validity-container {
    display: inline-block;
  }

  .validity-container-error {
    border-radius: 4px;
    border: 1px solid $danger-color;
    overflow: hidden;
  }

  .component-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
}

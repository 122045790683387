@use "sass:color";@use 'alva' as *;.control-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.missing-data-container {
  display: flex;
  flex-direction: column;
}

.missing-data-link {
  font-size: 1.2rem;
  font-weight: 800;
}

.missing-data-link-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.missing-data-description {
  margin-bottom: 10px;
}

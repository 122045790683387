@use "sass:color";@use 'alva' as *;.Loader {
  display: flex;
  flex-flow: column;
  align-items: center;

  .redirect {
    width: 500px;
    max-width: 100%;
    color: #fff;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
  }

  .loading-text {
    width: 100%;
    margin-top: 10px;

    display: flex;
    justify-content: center;
  }
}

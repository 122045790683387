@use "sass:color";@use 'alva' as *;.m-body > div {
  width: 100%;
}

#content-and-subheader {
  /* From m-footer in style.bundle.css */
  $footer-height: 60px;

  display: flex;
  flex-flow: column;
  min-height: calc(100% - $footer-height);

  .m-content {
    display: flex;
    flex-flow: column;
    flex-grow: 1;

    .m-portlet {
      flex-grow: 1;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &:has(.scrollable-main-content) {
    overflow-x: scroll !important;
    overflow-y: scroll !important;
    height: calc(100vh - 140px) !important;
  }
}

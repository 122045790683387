@use "sass:color";@use 'alva' as *;.modal-xl {
  max-width: 1200px;
  padding: 0.5rem;
}

.modal-xxl {
  width: 90%;
  max-width: 1680px;
  padding: 0.5rem;
}

@use "sass:color";@use 'alva' as *;.Parkabrechnung {
  .tabs,
  .TabBar {
    padding: 5px;
    background-color: white;
    font-size: 15px;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
  }
}

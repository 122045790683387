@use "sass:color";@use 'alva' as *;// NOTE: the !important definitions are necessary for now to override the Metronic styles

.ButtonFilledDisabled {
  background-color: $custom-light-grey !important;
  border: 1px solid $custom-light-grey !important;
  color: $custom-grey !important;
}

.ButtonOutlineDisabled {
  border: 1px solid $custom-light-grey !important;
  color: $custom-grey !important;
  background: inherit !important;
}

.ButtonLinkDisabled {
  color: $custom-grey !important;
}

.ButtonNoBorder {
  border: 0 !important;
}

.ButtonNoBackground {
  background: none !important;

  &:hover:not(:disabled),
  &:focus,
  &:active {
    background: none;

    &.btn-outline-brand {
      color: $brand-color !important;
    }

    &.btn-outline-danger {
      color: $danger-color !important;
    }

    &.btn-outline-info {
      color: $info-color !important;
    }

    &.btn-outline-primary {
      color: $primary-color !important;
    }

    &.btn-outline-warning {
      color: $warning-color !important;
    }
  }
}

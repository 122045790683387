@use "sass:color";@use 'alva' as *;.energy-assignment-item--next {
  border: 2px solid $warning-color !important;
  border-radius: 5px !important;
}

.energy-assignment-item--next + div {
  border-top-style: none;
}

.energy-assignment-item {
  height: 100%;
  width: 100%;
  border: 1px solid #45474d36;
  border-bottom-style: none;
  padding: 12px;
}

.energy-assignment-item:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.energy-assignment-item:last-child {
  border-bottom-style: solid;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.next-assignment-text {
  color: $custom-grey;
}

.next-assignment-title {
  margin: 0;
  font-weight: 600;
  font-size: 1.4em;
  line-height: 0.9;
  display: flex;
  justify-content: space-between;
}

.next-assignment-title-inactive {
  color: $custom-grey;
}

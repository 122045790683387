@use "sass:color";@use 'alva' as *;.FormFieldInfoText {
  margin-top: 25px;
  margin-bottom: 1rem;

  .info-text {
    margin-left: 10px;

    p {
      margin: 0;
    }
  }
}

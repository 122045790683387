@use "sass:color";@use 'alva' as *;.generate-documents {
  margin-top: 20px;
}

.document-generation-warning {
  margin-bottom: 10px;

  p:last-of-type {
    margin-bottom: 0;
  }
}
.generate-documents-button-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
}

.generate-button {
  margin-bottom: 10px;
  align-self: center;
  text-align: center;
}

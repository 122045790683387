@use "sass:color";@use 'alva' as *;.product-link {
  &:hover {
    text-decoration: none;
  }
}

.analyzer {
  h1 {
    color: $brand-color;
  }

  &:hover {
    h1 {
      color: color.scale($brand-color, $lightness: 8%);
    }
  }
}

.manager {
  h1 {
    color: $secondary-brand-color;
  }

  &:hover {
    h1 {
      color: color.scale($secondary-brand-color, $lightness: 8%);
    }
  }
}

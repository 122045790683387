@use "sass:color";@use 'alva' as *;.todo-calendar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.todo-calendar-icons {
  i {
    cursor: pointer;
    &.delete-icon {
      color: $danger-color;
    }
    &.refresh-icon {
      color: #00008b;
    }
    &.copy-icon {
      color: #00008b;
    }
  }
  a {
    padding-left: 5px;
  }
}

div.custom-alert.alert.alert-warning {
  color: #856012;
  background-color: #ffebc1;
  border-color: #ffebc1;
}

@use "sass:color";@use 'alva' as *;.participation-warning-modal-header {
  .modal-title {
    display: flex;
    align-items: center;
    flex-direction: row;
    .participation-warning-moda-header-text {
      margin-left: 15px;
      margin-top: 4px;
      font-weight: bold;
      font-size: 1.3rem;
    }
  }
}

@use "sass:color";@use 'alva' as *;.ContractDeliveryTable {
  .contract-delivery-table-actions-and-missing-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .contract-delivery-table-actions-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 3px;
    }
  }

  .Table {
    border: none;
    .sub-component {
      border: 1px solid $custom-light-grey;
      td {
        border: none;
      }
    }
    .basic-row {
      border: 1px solid $custom-light-grey;
      .contract-delivery-table-expand-row-icon {
        cursor: pointer;
        margin-left: 10px;
      }
      .basic-cell {
        border-right: none !important;
      }
    }
  }
}

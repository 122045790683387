@use "sass:color";@use 'alva' as *;.HeaderWithCustomSorting {
  width: 100%;

  .header-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;

    .header-component {
      max-width: calc(100% - 32px);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .sorting-icon {
      position: absolute;
      right: 4px;
      color: white;
    }
  }
}

@use "sass:color";@use 'alva' as *;.IconAlert {
  .icon-alert-content {
    font-weight: 500;
  }

  .icon-alert-icon {
    margin-right: 10px;
    font-size: 25px;
    width: 20px;
  }
}

.CustomInfoAlert {
  background-color: #cae3ff !important;
  border: 1px solid #aed5ff !important;

  .info-alert-content {
    color: #00439e;
  }
}

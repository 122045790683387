@use "sass:color";@use 'alva' as *;.FormItem {
  .select2 {
    .form-control {
      display: none;
    }
    .is-invalid ~ .select2 .selection .select2-selection--single {
      border-color: $danger-color;
    }
    .warning ~ .select2 .selection .select2-selection--single {
      border-color: $warning-color;
    }
  }
  .form-control.warning {
    border-color: $warning-color;
  }
  .label-container {
    display: flex;
    justify-content: space-between;

    .help-icon {
      margin-left: 10px;
    }
  }

  .DateInput2 {
    input {
      border-color: #ebedf2;
    }
  }
}

.label-title-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.form-item-info-text-container {
  margin-top: 25px;
  .form-item-info-text {
    margin-left: 10px;
    p {
      margin: 0;
    }
  }
}

@use "sass:color";@use 'alva' as *;.ThirdPartySystemsList {
  width: 100%;

  .third-party-systems-list-controls {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    flex-direction: row;
    column-gap: 0.5rem;

    button {
      margin-left: 3px;
    }
  }

  .rt-resizable-header-content {
    text-align: left;
  }

  .rt-td {
    display: flex;
    align-items: center;
  }

  .file-type {
    margin-right: 3px;
  }

  .icons {
    text-align: right;

    .la {
      margin: 0 3px;
      cursor: pointer;
    }
  }

  .no-data-component {
    text-align: center;
    margin: 10px;

    p {
      margin: 0;
    }
  }
}

@use "sass:color";@use 'alva' as *;.Tile {
  display: flex;

  &.m-portlet {
    margin-bottom: 0px;
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0px;
    }
  }

  .tile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid $custom-light-grey;

    img {
      margin-right: 1rem;
    }

    .Icon {
      margin-right: 1rem;
    }

    .tile-filter {
      font-size: 13px;
      font-weight: 300;
      width: 300px;
    }
  }

  .m-portlet__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .tile-body {
      display: flex;
      flex-grow: 1;
      justify-content: center;

      .NoDataComponent {
        font-size: 1.75rem;
      }
    }
  }
}

@use "sass:color";@use 'alva' as *;.ChangeableFieldTable {
  .text-value-cell-container {
    .table-error-messages-container {
      width: 300px;
      text-wrap: wrap;
      .table-error-message {
        color: $danger-color;
        font-weight: 600;
        font-size: 0.9em;
      }
    }
  }
  .help-icon {
    -webkit-text-stroke: 1px white;
    font-size: 1.3rem;
  }

  .icon-help-text-tooltip {
    font-weight: normal;
    white-space: normal;
  }

  .rt-resizable-header-content {
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rt-td {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .number-value-cell {
    margin-bottom: 0;
  }

  .company-cell {
    label {
      display: none;
    }

    .FormField {
      width: 245px;
      margin: 0 0.5rem 0 0;
    }

    .collapse {
      label {
        display: unset;
      }

      .FormField {
        width: unset;
        margin: unset;
      }
    }

    .CreatableDropdown {
      .CustomForm {
        button {
          margin-top: 10px;
        }
      }
    }
  }

  .center {
    input {
      text-align: center;
    }
  }

  .DateFields {
    margin-bottom: 0;
  }
}

@use "sass:color";@use 'alva' as *;.vollmacht-assignment-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  .missing-data-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    .missing-data-button {
      width: 100%;
      margin-top: 3px;
    }
    .msb-dropdown-button {
      margin-bottom: 5px;
    }
    .missing-data-button--secondary-with-border {
      color: $brand-color;
      border: 1px solid $brand-color;
    }
    .msb-dropdown-menu {
      width: 100%;
    }
  }
  .msb-current-user-document-container {
    display: flex;
    flex-direction: column;
    .msb-current-user-document {
      font-weight: 700;
      overflow: hidden;
      max-width: 30ch;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

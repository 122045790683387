@use "sass:map";

$optifont-font: "optifont";

@font-face {
  font-family: $optifont-font;
  src:
    url("./fonts/global/optifont.woff?b93f4e712ec6ccd6a441d84ffec570f1")
      format("woff"),
    url("./fonts/global/optifont.woff2?b93f4e712ec6ccd6a441d84ffec570f1")
      format("woff2");
}

i[class^="op-"]:before,
i[class*=" op-"]:before {
  font-family: optifont !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$optifont-map: (
  "erloesmonitoring": "\f101",
  "guarantee-of-origin": "\f102",
  "opti-charge": "\f103",
  "paragraph-6": "\f104"
);

.op-erloesmonitoring:before {
  content: map.get($optifont-map, "erloesmonitoring");
}
.op-guarantee-of-origin:before {
  content: map.get($optifont-map, "guarantee-of-origin");
}
.op-opti-charge:before {
  content: map.get($optifont-map, "opti-charge");
}
.op-paragraph-6:before {
  content: map.get($optifont-map, "paragraph-6");
}

@use "sass:color";@use 'alva' as *;.DatePickerWithRange {
  .mantine-Input-input {
    max-width: 97px;
    font-family: sans-serif, Arial;
    font-size: 1rem;
    font-weight: initial;
    line-height: 1.25;
    color: $node-energy-black;
  }
}

.date-presets {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-bottom: 3px;

  .single-presets,
  .dropdown-row {
    .btn {
      margin: 2px;
    }
  }
}

@use "sass:color";@use 'alva' as *;.ClarifyTodoModal {
  .modal-body {
    h5 {
      margin-top: 1.5em;
    }
  }

  .description {
    button {
      margin-top: 13px;
    }
  }

  .delete-warning {
    margin-top: 2em;
  }

  .dropdown {
    .dropdown-toggle {
      min-width: 205px;

      &.dropdown-decision-confirmed {
        color: $status-confirmed-color;
        background-color: $status-confirmed-background;
        border-color: $status-confirmed-border;
      }

      &.dropdown-decision-confirmed-finished {
        color: $status-confirmed-finished-color;
        background-color: $status-confirmed-finished-background;
        border-color: $status-confirmed-finished-border;
      }

      &.dropdown-decision-rejected {
        color: $status-rejected-color;
        background-color: $status-rejected-background;
        border-color: $status-rejected-border;
      }

      &.dropdown-decision-unclear {
        color: $status-unclear-color;
        background-color: $status-unclear-background;
        border-color: $status-unclear-border;
      }
    }
  }
}

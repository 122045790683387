@use "sass:color";@use 'alva' as *;.HistoricalDataImport {
  .description {
    margin-top: 20px;
  }

  #historical-data-import-range-date-picker {
    display: flex;
    justify-content: center;
    margin: 30px 0 20px 0;

    .DateInput__small {
      width: 185px;

      .DateInput_input {
        text-align: center;
      }
    }
  }
}

.historical-data-import-controls {
  .skip {
    margin-right: 3px;
  }
}

.DateRangePicker_picker {
  z-index: 9999;
}

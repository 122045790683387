@use "sass:color";@use 'alva' as *;.table-main-component {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);

  thead {
    background: $brand-color;

    th {
      position: relative;
      padding: 5px 5px;
      line-height: normal;
      border-right: 1px solid rgb(0, 159, 150);
      font-weight: 500;
      color: #fff;

      .sortable-header {
        display: flex;
        align-items: center;
        justify-content: center;

        &.can-sort {
          cursor: pointer;
          user-select: none;
        }

        .sorting-icon {
          margin-left: auto;
          color: white;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        border-right: 1px solid rgba(0, 0, 0, 0.02);
        text-overflow: ellipsis;
        padding: 7px 5px;
        overflow: hidden;
      }
    }

    tr:nth-child(even) {
      background: rgba(0, 0, 0, 0.03);
    }
  }

  .resizer {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 5px;
    background: rgba(0, 0, 0, 0.5);
    cursor: col-resize;
    user-select: none;
    touch-action: none;
  }

  .resizer.isResizing {
    background: blue;
    opacity: 1;
  }

  @media (hover: hover) {
    .resizer {
      opacity: 0;
    }

    *:hover > .resizer {
      opacity: 1;
    }
  }
}

@use "sass:color";@use 'alva' as *;.EdaVisualization {
  display: flex;
  flex-flow: column;
  overflow: hidden;

  // v1 only
  .eda-buttons {
    display: flex;
    flex-flow: row;
    margin-bottom: 10px;

    .date-filter {
      display: flex;
      align-items: center;

      .label {
        margin-right: 10px;
      }

      .DatePicker {
        width: 100px;
      }
    }

    .control-buttons {
      margin-left: auto;
    }

    button {
      margin-left: 5px;
    }
  }
}

// v2 only
:not(.eda-buttons) .control-buttons {
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

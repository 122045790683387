@use "sass:color";@use 'alva' as *;.Paragraph6AvailableCreditsModal {
  table {
    table-layout: fixed;
    min-width: 100%;
    font-size: 14px;
    font-weight: 400;
  }

  tr {
    &:not(:first-child) {
      border-top: 1px solid $custom-light-grey;
    }
  }

  td > span {
    display: flex;
    align-items: center;
    justify-content: right;
  }

  td:nth-child(1) {
    padding: 15px 20px 15px 0;
    word-wrap: break-word;
  }

  td:nth-child(2) {
    width: 25%;
  }

  td:nth-child(3) {
    width: 25%;
    text-align: right;
  }
}

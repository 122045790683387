@use "sass:color";@use 'alva' as *;.MeterDataLoadingWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 48px;

  .MeterDataLoader {
    margin: 24px 0;
  }

  .MeterDataLoadingText {
    max-width: 380px;
    text-align: center;

    .MeterDataLoadingHeader {
      font-size: 16px;
      margin-bottom: 16px;
    }

    .MeterDataParagraph {
      font-size: 12px;
    }
  }
}

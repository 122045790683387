@use "sass:color";@use 'alva' as *;.NodePopup {
  position: absolute;
  top: 200px;
  left: 200px;
  z-index: 1;
  max-width: 290px;
  min-width: 170px;

  display: flex;
  flex-flow: column;

  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  padding: 9px 14px;
  text-align: left;
  word-wrap: break-word;
  line-height: 20px;

  .small {
    font-size: 80%;
    font-weight: 400;
  }

  .link-disabled {
    color: $custom-light-grey;
  }

  .name {
    font-weight: 700;
  }

  .inactive {
    font-style: italic;
  }

  .links {
    display: flex;
    flex-flow: column;
  }

  .custom-icon {
    width: 32px;
    height: 32px;
    padding: 2px;
    border: 1px solid black;
    margin: 3px;
  }

  .custom-icon:hover {
    cursor: pointer;
    border-color: $brand-color;
  }

  .selected-icon {
    border-color: $brand-color;
  }
}

@use "sass:color";@use 'alva' as *;.copy-to-clipboard-container {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: calc(1.3rem + 4px);

  .copy-to-clipboard-icon {
    display: none;
    margin-left: 4px;
    height: 100%;
    padding: 4px;
    cursor: pointer;
    background-color: #ffffff;

    &.clicked {
      background-color: #004379;
    }
  }

  &:hover {
    .copy-to-clipboard-icon {
      display: block;
    }
  }
}

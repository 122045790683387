@use "sass:color";@use 'alva' as *;.small-height {
  height: 10px;
}

.contract-deliveries-controls-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
}

.contract-deliveries-no-data-overview {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contract-deliveries-no-data-header {
  margin-bottom: 30px;
  font-weight: 600;
}

.contract-deliveries-no-data-description {
  margin-bottom: 30px;
  color: $custom-grey;
  font-weight: 600;
}

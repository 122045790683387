@use "sass:color";@use 'alva' as *;.FileList {
  margin: 0 auto;
  margin-top: 1em;
  width: 90%;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .file {
    margin-bottom: 10px;

    .card {
      border: 1px solid rgba(0, 0, 0, 0.125);
    }

    .la {
      font-size: 2rem;
      color: green;
    }

    .file-body {
      display: flex;
      align-items: center;
      padding: 5px 5px;

      .name {
        flex-grow: 1;
        overflow: auto;
        margin-right: 5px;
      }

      .file-dropdown {
        min-width: 300px;
        margin-right: 5px;

        .FormField {
          margin: 0;
        }
      }

      .file-dropdown + .file-dropdown {
        margin-top: 5px;
      }

      .btn-close:before {
        content: "x";
      }

      .btn-close:before {
        font-size: 1.2rem;
      }

      .btn-close span {
        display: none;
      }
    }
  }
}

@use "sass:color";@use 'alva' as *;.StaffViewToggle {
  .m-switch label {
    margin-bottom: 0px;
    display: flex;
    flex-flow: row-reverse;
  }

  .m-switch.m-switch--sm input:empty ~ span {
    margin-right: 0;
    margin-left: 10px;
  }
}

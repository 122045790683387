@use "sass:color";@use 'alva' as *;.UploadDropzone {
  border: 1px dashed $custom-light-grey;
  margin: 1em auto;
  padding: 1.5em;
  width: 90%;
  display: flex;
  flex-flow: column;
  align-items: center;

  .upload-icon-container {
    .upload-icon {
      font-size: 35px;
      color: $custom-light-grey;
    }
  }

  .upload-button {
    margin: 15px 0 5px 0;
  }
}

@use "sass:color";@use 'alva' as *;.ChangeableSpotMarketFormulaFieldStatic {
  .label-and-button {
    display: flex;
    align-items: center;

    .label-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;
      margin: 0 0.5rem 0 0;
      border-color: #ebedf2;
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      min-height: 38px;
      cursor: pointer;
      .label-container-icon-and-value {
        display: flex;
        flex-direction: row;
        align-items: center;
        .toggle-icon {
          border-color: #ebedf2;
          border-style: solid;
          border-width: 0 1px 0 0;
          min-height: 38px;
          width: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 0.5rem 0 0;
        }
      }

      &.disabled {
        background-color: $custom-very-light-grey;
      }
    }

    .label-button {
      margin-left: auto;
    }
  }

  .formulas-edit,
  .loading-card {
    margin-top: 0.5rem;
  }
}

@use "sass:color";@use 'alva' as *;.StructureViewDiagram {
  width: 100%;
  height: 100%;
  position: relative;
  flex-grow: 1;

  &.connecting-nodes {
    border: 1px solid red;
  }

  &.edge-to-save {
    border: 1px solid green;
  }

  .StructureViewDiagramControls {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 0;
    top: 66%;
    width: 45px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    overflow: hidden;

    .StructureViewDiagramControlsPercentage {
      text-align: center;
      margin: 0.5em 0;
    }

    .StructureViewDiagramControlsBtn {
      background-color: unset;
      border: unset;
      outline: unset;
      font-size: 2rem;
      font-weight: 300;
      transition: background-color 300ms ease-in-out;

      &:hover {
        &:not(:disabled) {
          cursor: pointer;
          background-color: #dfdfdf;
        }
      }
    }
  }
}

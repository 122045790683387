@use "sass:color";@use 'alva' as *;#search-results {
  margin-top: 30px;
  height: 65vh;
  overflow-y: auto;

  .list-group-item {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .list-group-item.focused {
    background-color: $brand-color;
    color: #ffffff;
  }

  .list-group-item:hover {
    cursor: pointer;
  }

  .search-result-box {
    display: grid;
    grid-template-columns: 11fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .search-result-name {
    font-weight: bolder;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-end: 1;
    grid-row-start: 1;
  }

  .search-result-id {
    font-weight: lighter;
    font-size: smaller;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
  }

  .search-result-focus-icon {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: span 2;
    justify-self: end;
    align-self: center;

    .la {
      font-size: xx-large;
    }
  }

  #load-more-button {
    margin-top: 10px;
  }
}

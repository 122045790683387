@use "sass:color";@use 'alva' as *;.Input {
}

.FormField .invalid-feedback {
  display: block;
  color: $danger-color;
}

.FormField .warning-feedback {
  display: block;
  font-size: 80%;
  margin-top: 0.25rem;
  color: $warning-color;
}

.details {
  border-bottom: 1px solid #e9ecef;
}

.modal-header {
  word-break: break-all;
}

.footer-buttons {
  & > :first-of-type {
    margin-right: 3px;
  }
}

@use "sass:color";@use 'alva' as *;.TodosDueSoon {
  border: 1px solid $custom-light-grey;
  height: 200px;
  overflow-y: auto !important;
  flex-grow: 1;

  .header {
    .Icon {
      color: $brand-color;
    }
  }

  .due-soon-element {
    .label-with-link {
      color: $primary-color;
      cursor: pointer;
      overflow: hidden;
      text-wrap: nowrap;
      text-overflow: ellipsis;
    }
  }

  .no-due-soon {
    .Icon {
      color: $brand-color;
    }
  }
}

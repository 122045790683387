.Toastify {
  .Toastify__toast-container {
    width: auto;
  }

  .Toastify__toast {
    --toastify-color-success: #d1e7dd;
    --toastify-border-color-success: #badbcc;
    --toastify-text-color-success: #0f5132;
    --toastify-icon-color-success: #0f5132;

    --toastify-color-info: #cfe2ff;
    --toastify-border-color-info: #b6d4fe;
    --toastify-text-color-info: #084298;
    --toastify-icon-color-info: #084298;

    --toastify-color-warning: #fff3cd;
    --toastify-border-color-warning: #ffecb5;
    --toastify-text-color-warning: #664d03;
    --toastify-icon-color-warning: #ffc107;

    --toastify-color-error: #f8d7da;
    --toastify-border-color-error: #f5c2c7;
    --toastify-text-color-error: #842029;
    --toastify-icon-color-error: #842029;

    width: var(--toastify-toast-width);
    border: 1px solid;
    font-weight: 400;

    .Toastify__close-button {
      color: #000;
    }
  }

  .Toastify__toast-theme--colored.Toastify__toast--success {
    border-color: var(--toastify-border-color-success);

    .Toastify__toast-icon {
      color: var(--toastify-icon-color-success);
    }
  }

  .Toastify__toast-theme--colored.Toastify__toast--info {
    border-color: var(--toastify-border-color-info);

    .Toastify__toast-icon {
      color: var(--toastify-icon-color-info);
    }
  }

  .Toastify__toast-theme--colored.Toastify__toast--warning {
    border-color: var(--toastify-border-color-warning);

    .Toastify__toast-icon {
      color: var(--toastify-icon-color-warning);
    }
  }

  .Toastify__toast-theme--colored.Toastify__toast--error {
    border-color: var(--toastify-border-color-error);

    .Toastify__toast-icon {
      color: var(--toastify-icon-color-error);
    }
  }
}

@use "sass:color";@use 'alva' as *;.EditSaveCell {
  display: flex;
  width: 100%;

  .Text,
  .Input {
    flex-grow: 1;
  }

  .Text {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .placeholder {
    color: $custom-grey;
  }

  .FormField {
    margin: 0;
    margin-right: 3px;
  }

  .Dropdown {
    margin: 0;
    margin-right: 3px;
    width: calc(100% - 13px);
  }

  .DropdownLabel {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Controls {
    display: flex;
    align-items: center;
    margin-left: auto;

    .edit-icon {
      font-size: 17px;
    }
  }
}

@use "sass:color";@use 'alva' as *;.CustomForm {
  .more-data-needed {
    margin-top: 20px;
  }
}

.custom-form-controls-inner {
  & > :not(:last-child) {
    margin-right: 0.25rem !important;
  }

  & > :not(:first-child) {
    margin-left: 0.25rem !important;
  }
}

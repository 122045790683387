@use "sass:color";@use 'alva' as *;.MeterSelectionWrapper {
  display: flex;
  align-items: center;
  padding-right: 22px;

  .Dropdown {
    width: 100%;
    max-width: 180px;
    margin-bottom: 0;
  }

  .MeterSaveIconWrapper {
    min-width: 20px;
    height: 20px;
    margin-left: 16px;

    .MeterSaveIcon {
      font-size: 20px;
      color: $brand-color;
      transition: opacity 300ms ease-in;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }
}

.MeterSwitchWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;

  .SavedMeterText {
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
  }

  .MeterRemoveIcon {
    font-size: 20px;
  }
}
